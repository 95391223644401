import "core-js/modules/es.array.push.js";
import { useCssVars as _useCssVars, unref as _unref } from 'vue';
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

const _withScopeId = n => (_pushScopeId("data-v-7455d5c2"), n = n(), _popScopeId(), n);

const _hoisted_1 = {
  class: "top-navigator"
};
const _hoisted_2 = {
  class: "left-compoent-wrapper"
};
const _hoisted_3 = {
  key: 1
};
const _hoisted_4 = {
  key: 0,
  class: "navigator-item page-title"
};
const _hoisted_5 = {
  class: "right-compoent-wrapper"
};
const _hoisted_6 = {
  key: 3
};
import { ref, onBeforeMount } from "vue";
import router from "../router/index";
import { useRoute } from "vue-router";
export default {
  __name: 'TopNavigator',
  props: {
    title: String,
    //导航背景色。primary：黑底白字，special：白底黑字
    type: {
      type: String,
      default: "primary"
    },
    // goBack:显示返回图标,mine:显示个人中心,hidden:不显示任何元素
    leftComponent: {
      type: String,
      default: "goBack"
    },
    // myGoods:显示藏品馆; myRecords:兑换记录; 不传参数不显示任何元素
    rightComponent: {
      type: String,
      default: ""
    }
  },

  setup(__props) {
    const props = __props;

    _useCssVars(_ctx => ({
      "321baccb": _unref(bgColor),
      "9e3c64ba": _unref(textColor)
    })); //背景色处理


    let customStyle = {
      bgColor: "#ffffff",
      textColor: "#000000"
    };

    if (props.type === "special") {
      customStyle = {
        bgColor: "#000000",
        textColor: "#ffffff"
      };
    }

    const {
      bgColor,
      textColor
    } = customStyle;
    const curUrlName = ref("");

    const handleGoBack = () => {
      if (window.history.state && window.history.state.back) {
        router.go(-1);
      } else {
        router.push("/");
      }
    };

    const handleGotoMine = () => {
      router.push("/mine");
    };

    onBeforeMount(() => {
      const curRouter = useRoute();
      curUrlName.value = curRouter.name;
    });

    const handleGotoCollection = () => {
      router.push("/collection-room");
    };

    const handleGoToRecoreds = () => {
      router.push("/redeem-records");
    }; //刷新页面


    const iconRotate = ref(false);

    const handleRefreshPage = () => {
      iconRotate.value = true;
      location.reload();
    };

    return (_ctx, _cache) => {
      const _component_van_icon = _resolveComponent("van-icon");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [__props.leftComponent === 'mine' ? (_openBlock(), _createBlock(_component_van_icon, {
        key: 0,
        name: "contact",
        size: "20",
        onClick: handleGotoMine
      })) : __props.leftComponent === 'hidden' ? (_openBlock(), _createElementBlock("div", _hoisted_3)) : (_openBlock(), _createBlock(_component_van_icon, {
        key: 2,
        name: "arrow-left",
        size: "20",
        onClick: handleGoBack
      }))]), __props.title != 'hidden' ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(__props.title || "极客公园创新大会 2023"), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_5, [__props.rightComponent === 'myGoods' ? (_openBlock(), _createBlock(_component_van_icon, {
        key: 0,
        name: "https://summit-img.geekpark.net/nft-market/钻石iconT.png",
        size: "20",
        class: "navigator-item rotate-icon",
        onClick: handleGotoCollection
      })) : _createCommentVNode("", true), __props.rightComponent === 'myRecords' ? (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: "my-records",
        onClick: handleGoToRecoreds
      }, "兑换记录")) : _createCommentVNode("", true), __props.rightComponent === 'refresh' ? (_openBlock(), _createBlock(_component_van_icon, {
        key: 2,
        name: "replay",
        size: "20",
        class: _normalizeClass(['navigator-item', iconRotate.value ? 'go' : '']),
        onClick: handleRefreshPage
      }, null, 8, ["class"])) : (_openBlock(), _createElementBlock("div", _hoisted_6))])]);
    };
  }

};